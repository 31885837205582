import { Animate } from "./Animate";

export class Chcekout {
    readonly SERVICE = 'checkout';

    private form: HTMLFormElement;
    private paymentGateways: HTMLElement;
    
    constructor() {
        this.form = document.getElementById('checkout') as HTMLFormElement;
        this.paymentGateways = document.getElementById('payment-gateways');

        if(this.form) {
            this.addEventListeners();
        }
    }

    private shipToBillingToggle(checked: boolean) {
        const shippingForm = this.form.querySelector('#shipping') as HTMLElement;
        const copyFromShippingToCisco = this.form.querySelector('#copyFromShippingToCisco') as HTMLInputElement;
        if(checked) {
            Animate.slideDown(shippingForm);
            Animate.slideDown(copyFromShippingToCisco);
        } else {
            Animate.slideUp(shippingForm);
            Animate.slideUp(copyFromShippingToCisco);
        }
    }

    private copyFields(from: string, to: string) {
        const values = {} as any;
        ([...this.form.querySelectorAll('#' + from + ' input')] as HTMLInputElement[]).forEach((element) => {
            values[element.name.replace(from, to)] = element.value;
        });

        ([...this.form.querySelectorAll('#' + to + ' input')] as HTMLInputElement[]).forEach((element) => {
            element.value = values[element.name] ? values[element.name] : '';
        });
    }

    private onPaymentChange() {
        const gateways = [...this.paymentGateways.querySelectorAll('.payment-method')];
        gateways.forEach(gateway => {
            const input = gateway.querySelector('input');
            const description = gateway.querySelector('.description') as HTMLElement;
            if(description) {
                input.checked ? Animate.slideDown(description) : Animate.slideUp(description);
            }
        });

        const paypal = document.getElementById('payment_method_ppcp-gateway') as HTMLInputElement;
        const card = document.getElementById('payment_method_ppcp-credit-card-gateway') as HTMLInputElement;

        if(paypal && paypal.checked) {
            Animate.slideDown(document.querySelector('#ppc-button-ppcp-gateway'));
            Animate.slideUp(document.querySelector('#ppcp-hosted-fields'));
            Animate.slideUp(document.querySelector('#place_order'));

        } else if(card && card.checked) {
            Animate.slideUp(document.querySelector('#ppc-button-ppcp-gateway'));
            Animate.slideDown(document.querySelector('#ppcp-hosted-fields'));
            Animate.slideUp(document.querySelector('#place_order'));
        } else {
            Animate.slideUp(document.querySelector('#ppc-button-ppcp-gateway'));
            Animate.slideUp(document.querySelector('#ppcp-hosted-fields'));
            Animate.slideDown(document.querySelector('#place_order'));
        }
    }

    private onCeateAccountWithCheckout(input: HTMLInputElement) {
        this.form.querySelectorAll('.unblock-on-create-account').forEach((element: HTMLElement) => {
            if(input.checked) {
                Animate.slideDown(element);
            } else {
                Animate.slideUp(element);
            }
        })
    }

    private addEventListeners() {
        const shipToBillingCheckbox = this.form.querySelector('input[name="ship_to_different_address"]') as HTMLInputElement;
        shipToBillingCheckbox.addEventListener('change', () => {
            this.shipToBillingToggle(shipToBillingCheckbox.checked);
        });

        this.form.querySelector('#copyFromBillingToCisco')?.addEventListener('click', () => {
            this.copyFields('billing', 'cisco');
        });
        this.form.querySelector('#copyFromShippingToCisco')?.addEventListener('click', () => {
            this.copyFields('shipping', 'cisco');
        });

        [...this.form.querySelectorAll('input[name="payment_method"]')].forEach(e => e.addEventListener('change', () => this.onPaymentChange()));

        this.form.querySelector('#create-with-checkout')?.addEventListener('change', event => this.onCeateAccountWithCheckout(event.currentTarget as HTMLInputElement))
    }
}