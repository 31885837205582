import { Ajax } from "./Ajax"; 
import { Animate } from './Animate';
import { Messages } from "./Messages";

export class Quote {
    private quoteList: HTMLElement;

    constructor() {
        this.quoteList = document.querySelector('#quotelist');
        if(this.quoteList) {
            this.addEventListeners();
        }
    }

    public toggle(id: string) {
        const formData = new FormData();

        formData.append('action', 'CA_Toggle_Quote');
        formData.append('id', id);

        return Ajax.requestForm(formData).then(response => {
            return this.handleResponse(response);
        });
    }

    private async onQuantityChange(input: HTMLInputElement) {
        const formData = new FormData();
        const item = input.closest('.item') as HTMLElement

        if(item.dataset.qty >= input.value) {
            item.querySelector('.lead-time').innerHTML = '1-3 days';
        } else {
            item.querySelector('.lead-time').innerHTML = 'Pending';
        }

        formData.append('action', 'CA_Update_Quote');
        formData.append('id', item.dataset.id);
        formData.append('qty', input.value);

        return Ajax.requestForm(formData).then((response) => this.handleResponse(response));
    }

    private async onTrashClick(element: HTMLElement) {
        const formData = new FormData();
        const item = element.closest('.item') as HTMLElement

        formData.append('action', 'CA_Remove_Quote');
        formData.append('id', item.dataset.id);

        return Ajax.requestForm(formData).then((response) => this.handleResponse(response)).then(() => Animate.slideUp(item).then(() => item.remove()));
    }

    private async onEmptyQuoteClick(element: HTMLElement) {
        const formData = new FormData();
        
        formData.append('action', 'CA_Empty_Quote');

        return Ajax.requestForm(formData).then(response => this.handleResponse(response));
    }

    private async onSendClick(element: HTMLElement) {
        const name = (document.getElementById('name') as HTMLInputElement).value;
        const company = (document.getElementById('company') as HTMLInputElement).value;
        const email = (document.getElementById('email') as HTMLInputElement).value;
        const phone = (document.getElementById('phone') as HTMLInputElement).value;
        const comments = (document.getElementById('comments') as HTMLInputElement).value;

        const type = (document.getElementById('type') as HTMLInputElement).value;
        const cisco_name = (document.getElementById('cisco_name') as HTMLInputElement).value;
        const cisco_email = (document.getElementById('cisco_email') as HTMLInputElement).value;
        const cisco_company = (document.getElementById('cisco_company') as HTMLInputElement).value;
        const cisco_company_address = (document.getElementById('cisco_company_address') as HTMLInputElement).value;
        const cisco_company_shipping_address = (document.getElementById('cisco_company_shipping_address') as HTMLInputElement).value;
        const cisco_smartnet_account = (document.getElementById('cisco_smartnet_account') as HTMLInputElement).value;

        const formData = new FormData();
        formData.append('action', 'CA_Send_Quote');
        formData.append('name', name);
        formData.append('company', company);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('comments', comments);

        formData.append('type', type);
        formData.append('cisco_name', cisco_name);
        formData.append('cisco_email', cisco_email);
        formData.append('cisco_company', cisco_company);
        formData.append('cisco_company_address', cisco_company_address);
        formData.append('cisco_company_shipping_address', cisco_company_shipping_address);
        formData.append('cisco_smartnet_account', cisco_smartnet_account);

        return Ajax.requestForm(formData).then(response => this.handleResponse(response));
    }

    private onDiscoutedQuoteClick(element: HTMLElement) {
        if(element.classList.contains('disabled')) {
            return;
        }

        element.classList.add('hidden');
        this.quoteList.querySelector('#standard-quote-button').classList.remove('hidden');
        Animate.slideDown(this.quoteList.querySelector('#end-user-details'));
        (this.quoteList.querySelector('#type') as HTMLInputElement).value = 'discounted';
    }

    private onStandardQuoteClick(element: HTMLElement) {
        element.classList.add('hidden');
        this.quoteList.querySelector('#discounted-button').classList.remove('hidden');
        Animate.slideUp(this.quoteList.querySelector('#end-user-details'));
        (this.quoteList.querySelector('#type') as HTMLInputElement).value = 'standard';
    }

    private handleResponse(response: any) {
        if(response.success) {
            document.querySelectorAll('#menu-quote .counter').forEach((element: HTMLElement) => {
                if(response.count > 0) {
                    element.classList.remove('hidden');
                    element.innerHTML = response.count;
                    element.style.animation = 'none';
                    element.offsetHeight;
                    element.style.animation = null;
                } else {
                    element.classList.add('hidden');
                }
            });
            if(this.quoteList) {
                if(response.count > 0) {
                    const discountLabel = this.quoteList.querySelector('.discount-label') as HTMLElement;
                    const discount = this.quoteList.querySelector('.discount') as HTMLElement;
                    if(response.discount) {
                        if(discountLabel.style.display == 'none') {
                            Animate.slideDown(discountLabel);
                            Animate.slideDown(discount);
                        }
                        discount.innerHTML = response.discount;
                    } else {
                        if(discountLabel.style.display == 'block') {
                            Animate.slideUp(discountLabel);
                            Animate.slideUp(discount);
                        }
                    }
                    
                    this.quoteList.querySelector('.subtotal').innerHTML = response.subtotal;
                    this.quoteList.querySelector('.tax').innerHTML = response.tax;
                    this.quoteList.querySelector('.total').innerHTML = response.total;
                    if(response.items) {
                        for (const key in response.items) {
                            if (Object.prototype.hasOwnProperty.call(response.items, key)) {
                                const item = response.items[key];
                                this.quoteList.querySelector('#product-' + key + ' .item-total').innerHTML = item.total;
                            }
                        }
                    }
                }
                if(response.isDiscountedAvailable) {
                    this.quoteList.querySelector('#discounted-button').classList.remove('disabled');
                } else {
                    (this.quoteList.querySelector('#type') as HTMLInputElement).value = 'standard';
                    this.quoteList.querySelector('#discounted-button').classList.add('disabled');
                    this.quoteList.querySelector('#discounted-button').classList.remove('hidden');
                    this.quoteList.querySelector('#standard-quote-button').classList.add('hidden');
                    Animate.slideUp(this.quoteList.querySelector('#end-user-details'));
                }
            }
        }

        if(response.messages && response.messages.length) {
            Messages.show(response.success, response.messages);
        }

        return response;
    }

    private addEventListeners() {
        this.quoteList.querySelectorAll('.item input').forEach((input: HTMLElement) => {
            input.addEventListener('change', event => this.onQuantityChange(event.currentTarget as HTMLInputElement));
        });
        this.quoteList.querySelectorAll('.item .trash').forEach((element: HTMLElement) => {
            element.addEventListener('click', event => this.onTrashClick(event.currentTarget as HTMLElement));
        });
        this.quoteList.querySelector('.empty-quote')?.addEventListener('click', event => this.onEmptyQuoteClick(event.currentTarget as HTMLElement))
        this.quoteList.querySelector('#send-quote')?.addEventListener('click', event => this.onSendClick(event.currentTarget as HTMLElement));
        this.quoteList.querySelector('#discounted-button')?.addEventListener('click', event => this.onDiscoutedQuoteClick(event.currentTarget as HTMLElement));
        this.quoteList.querySelector('#standard-quote-button')?.addEventListener('click', event => this.onStandardQuoteClick(event.currentTarget as HTMLElement));
    }
}