import { Ajax } from "./CloudApplliances/Ajax";
import { Account } from "./CloudApplliances/Account";
import { Animate } from './CloudApplliances/Animate';
import { Cart } from './CloudApplliances/Cart';
import { Chcekout } from "./CloudApplliances/Checkout";
import { Messages } from "./CloudApplliances/Messages";
import { Modal } from './CloudApplliances/Modal';
import { Product } from './CloudApplliances/Product';
import { Products } from './CloudApplliances/Products';
import { Quote } from "./CloudApplliances/Quote";

export class CloudApplliances {
    account: Account;
    cart: Cart;
    checkout: Chcekout
    modal: Modal;
    product: Product;
    products: Products;
    quote: Quote;
    
    constructor() {
        this.account = new Account();
        this.modal = new Modal();
        this.quote = new Quote();
        this.cart = new Cart(this.modal);
        this.checkout = new Chcekout();
        this.product = new Product(this.cart, this.quote);
        this.products = new Products(this.cart, this.quote);

        this.addEventListeners();
        this.initCookieConsent();
        this.initOnScrollEvent();
        
        Messages.hideWCMessages();
    }

    public showPaypalMessages(messages: any) {
        Messages.show(false, messages);
    }
    
    private initOnScrollEvent() {
        const header = document.querySelector('header') as HTMLElement;
        if(header) {
            window.onscroll = () => Animate.toggleHeaderShadow(header);
        }
    }

    private addEventListeners()
    {
        const mobileMenu = document.querySelector('#mobile-menu-overlay');
        [...document.querySelectorAll('.mobile-menu-button')].forEach(e => {
            e.addEventListener('click', () => mobileMenu?.classList.toggle('active'));
        });
        mobileMenu?.querySelectorAll('.dropdown').forEach(dropdown => {
            dropdown.addEventListener('click', e => {
                const arrow = (e.currentTarget as HTMLElement).querySelector('svg');
                if(arrow) {
                    arrow.style.transform = arrow.style.transform ? '' : 'rotate(180deg)';
                }
                const children = (e.currentTarget as HTMLElement).parentElement.querySelector('.children') as HTMLElement;
                if(children){
                    Animate.slideToggle(children);
                }
            });
        });

        [...document.querySelectorAll('.faq .question')].forEach(e => {
            e.addEventListener('click', () => {
                const parent = e.parentElement;
                if(parent) {
                    Animate.slideToggle(parent.querySelector('.answer') as HTMLElement);
                    parent.classList.toggle('active');
                }
            });
        });

        const contactSubmit = document.getElementById('contact-submit');
        contactSubmit?.addEventListener('click', e => this.onContactFormSubmit(e.currentTarget as HTMLElement));
    }

    private initCookieConsent() {
        let cookie_consent = this.getCookie("user_cookie_consent");
        if(cookie_consent == ''){
            const coockieNotice =  document.getElementById("cookie-notice");
            if(coockieNotice) {
                coockieNotice.style.display = "block";
            }
        }
        document.getElementById('accept-cookieconsent')?.addEventListener('click', () => {
            this.acceptCookieConsent()
        });
    }

    private setCookie(cname: string, cvalue: number, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    
    // Delete cookie
    private deleteCookie(cname: string) {
        const d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=;" + expires + ";path=/";
    }
    
    // Read cookie
    private getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    private onContactFormSubmit(submitButton: HTMLElement)
    {
        Animate.slideUp(submitButton);
        const name = (document.getElementById('contact-full-name') as HTMLInputElement).value;
        const phone = (document.getElementById('contact-phone') as HTMLInputElement).value;
        const email = (document.getElementById('contact-email') as HTMLInputElement).value;
        const product = (document.getElementById('contact-product') as HTMLInputElement).value;
        const message = (document.getElementById('contact-message') as HTMLInputElement).value;
        const areTermsChecked = (document.getElementById('contact-terms') as HTMLInputElement).checked;

        const formData = new FormData();
        formData.append('action', 'CA_Submit_Contact_Form');
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('product', product);
        formData.append('message', message);
        formData.append('areTermsChecked', areTermsChecked ? '1' : '0');

        Ajax.requestForm(formData).then(response => {
            Messages.show(response.success, response.messages);
            Animate.slideDown(submitButton);
        });
    }
    
    // Set cookie consent
    public acceptCookieConsent(){
        this.deleteCookie('user_cookie_consent');
        this.setCookie('user_cookie_consent', 1, 30);
        document.getElementById("cookie-notice").style.display = "none";
    }
}